import React, { useEffect, useState } from 'react'
import {
  CarrierSetUp,
  DispatchService,
  FreightBrokers,
  GetStarted,
  LoadingPage,
  OwnerOperators,
} from '../../components'

const JoinUsPage = () => {
  const [load, setLoad] = useState(true)
  useEffect(() => {
    setLoad(false)
  }, [])
  return (
    <>
      {load ? (
        <LoadingPage />
      ) : (
        <>
          <OwnerOperators />
          <CarrierSetUp />
          <FreightBrokers />
          <DispatchService />
          <GetStarted />
        </>
      )}
    </>
  )
}

export default JoinUsPage
