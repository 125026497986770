import React, { useEffect, useState } from 'react'
import { ContactUs, LoadingPage } from '../../components'

const ContactUsPage = () => {
  const [load, setLoad] = useState(true)
  useEffect(() => {
    setLoad(false)
  }, [])
  return <>{load ? <LoadingPage /> : <ContactUs />}</>
}

export default ContactUsPage
