import React from 'react'
import { Box, Button, Container, Image, Text } from '@chakra-ui/react'
import NotFound404 from '../../assets/image/404.png'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
  const navigate = useNavigate()
  return (
    <Box>
      <Container maxW="1200px" mx="auto">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minH="100vh"
          py="30px"
        >
          <Box>
            <Image src={NotFound404} maxW="360px" mx="auto" />
            <Text
              textAlign="center"
              fontSize={['30px', '50px', '70px']}
              my="10px"
              fontWeight="800"
            >
              404 Error
            </Text>
            <Text
              maxW="550px"
              mx="auto"
              textAlign="center"
              color="#6e727d"
              fontSize={['18px']}
              fontWeight="500"
              lineHeight="180%"
            >
              Ops! It seems like the page you are looking for is not available!
              Please try again with another page or go back to home.
            </Text>
            <Box display="flex" justifyContent="center" my="20px">
              <Button
                borderColor="#473bf0"
                variant="outline"
                px="40px"
                py="30px"
                color="#473bf0"
                fontSize="17px"
                onClick={() => {
                  navigate('/')
                  window.scroll(0, 0)
                }}
              >
                Back to Home
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default NotFound
