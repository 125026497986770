import React, { useEffect, useState } from 'react'
import {
  EducationalTraining,
  LoadingPage,
  RegisterCourse,
  TruckDispatcher,
} from '../../components'

const DispatchCoursesPage = () => {
  const [load, setLoad] = useState(true)
  useEffect(() => {
    setLoad(false)
  }, [])
  return (
    <>
      {load ? (
        <LoadingPage />
      ) : (
        <>
          <TruckDispatcher />
          <EducationalTraining />
          <RegisterCourse />
        </>
      )}
    </>
  )
}

export default DispatchCoursesPage
