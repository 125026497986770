import React from 'react'
import { Box, Container, Text } from '@chakra-ui/react'

const DispatchService = () => {
  const text = [
    {
      id: 1,
      theme: 'Advertisement',
      desc: 'Freight & Truck Dispatching Services',
      desc1: 'We do all the work, all you have to do is DRIVE!',
    },
    {
      id: 2,
      theme: 'TRUCK DISPATCH SERVICE',
      desc: 'Providing freight dispatching services throughout the US',
      desc1: 'Get your truck loaded',
    },
  ]
  return (
    <Box background="#F4F7FA">
      <Container maxW="1170px" mx="auto">
        <Box py="40px">
          <Text
            color="dark"
            fontFamily="Inter"
            fontWeight="700"
            fontSize={['30px', '36px']}
            textAlign="center"
            maxW="900px"
            mx="auto"
          >
            We save you time and make you money with our Truck Dispatch Services
          </Text>
          <Box
            display="flex"
            flexDirection={['column', 'column', 'row']}
            justifyContent="space-around"
            mb="40px"
          >
            {text.map((el) => (
              <Box display="flex" key={el.id} mt="30px">
                <Box
                  w="35px"
                  h="35px"
                  borderRadius="50%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  background="rgba(71,59,240,0.11)"
                  color="#473BF0"
                  mt="5px"
                  p="15px"
                  fontWeight="700"
                >
                  {el.id}
                </Box>
                <Box fontFamily="Inter" pl={['10px', '20px']}>
                  <Text fontSize="19px" color="dark" fontWeight="700" mb="10px">
                    {el.theme}
                  </Text>
                  <Text
                    fontSize="14px"
                    color="#7A7A7A"
                    fontWeight="400"
                    my="15px"
                  >
                    {el.desc}
                  </Text>
                  <Text fontSize="14px" color="#7A7A7A" fontWeight="400">
                    {el.desc1}
                  </Text>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default DispatchService
