import React, { useState } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react'
import { BsTelephoneFill } from 'react-icons/bs'
import { FaEnvelope } from 'react-icons/fa'
import { MdLocationOn } from 'react-icons/md'
import LoadForm from '../loading/LoadForm'
import BackdropExample from '../modal/modalApi'

const ContactUs = () => {
  const contact = [
    {
      icon: <BsTelephoneFill color="#ffffff" fontSize="20px" />,
      theme: 'Call us',
      link: 'tel:+1312-300-8203',
      messenger: '+1312-300-8203',
    },
    {
      icon: <FaEnvelope color="#ffffff" fontSize="20px" />,
      theme: 'Text Us',
      link: 'mailto:info.usdispatch@gmail.com',
      messenger: 'info.usdispatch@gmail.com',
    },
    {
      icon: <MdLocationOn color="#ffffff" fontSize="20px" />,
      theme: 'Visit us',
      messenger: '2425 W BRYN MAWR AVE, CHICAGO, IL 60659',
    },
  ]

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [load, setLoad] = useState(false)

  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  )

  const [overlay, setOverlay] = useState(<OverlayOne />)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [status, setStatus] = useState(false)
  const [text, setText] = useState('')

  const OpenModal = () => {
    setOverlay(<OverlayOne />)
    onOpen()
    setLoad(false)
  }

  function onSubmit(values) {
    setLoad(true)
    return new Promise((resolve) => {
      axios
        .post('https://usdiscorp.pythonanywhere.com/usdispatch/contact/', {
          ...values,
        })
        .then(({ data }) => {
          setStatus(true)
          setText('Data sent successfully!')
          OpenModal()
          //   console.log(data)
        })
        .catch((err) => {
          setStatus(false)
          setText(`An error occurred while sending data! ${err.message}`)
          OpenModal()
          //   console.log(err)
        })
      resolve()
    })
  }

  return (
    <Box background="#F4F7FA" mt="90px">
      <Container maxW="1170px" mx="auto">
        <BackdropExample
          isOpen={isOpen}
          onClose={onClose}
          overlay={overlay}
          status={status}
          text={text}
        />
        <Box>
          <Text
            textAlign="center"
            color="dark"
            fontFamily="Roboto"
            fontWeight="700"
            fontSize={['42', '52px', '62px']}
            pt="40px"
          >
            Contact us
          </Text>
          <Text
            fontFamily="Roboto"
            color="#6E727D"
            fontWeight="400"
            fontSize="19px"
            textAlign="center"
            mt={['10px', '20px']}
          >
            Feel free to contact us for any business or personal inquiries
          </Text>
          <Box
            display="flex"
            justifyContent="space-around"
            flexWrap="wrap-reverse"
            py="40px"
            w="100%"
            mt={['20px', '30px', '40px']}
          >
            <Box w={['100%', '100%', '50%']}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl
                  maxW="368px"
                  mx="auto"
                  background="#FFFFFF"
                  borderRadius="10px"
                  px={['20px', '24px']}
                  pt={['20px', '24px']}
                  pb={['50px', '74px']}
                  className="form__input"
                  position="relative"
                >
                  {load && <LoadForm />}
                  <Box mb="40px">
                    <FormLabel
                      htmlFor="your_name"
                      color="#161C2D"
                      fontSize="14px"
                      fontWeight="700"
                      fontFamily="Inter"
                    >
                      Your name
                    </FormLabel>
                    <Input
                      {...register('username', { required: 'Enter your name' })}
                      id="your_name"
                      borderColor={errors.username && '#ff0000'}
                      type="text"
                    />
                    <Text color="#ff0000" fontSize="12px" py="5px">
                      {errors.username && errors.username?.message}
                    </Text>
                  </Box>
                  <Box mb="40px">
                    <FormLabel
                      htmlFor="your_email"
                      color="#161C2D"
                      fontSize="14px"
                      fontWeight="700"
                      fontFamily="Inter"
                    >
                      Your email
                    </FormLabel>
                    <Input
                      {...register('email', {
                        required: 'Enter your email',
                        pattern: {
                          value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                          message: 'Enter a valid email',
                        },
                      })}
                      id="your_email"
                      borderColor={errors.email && '#ff0000'}
                      type="email"
                    />
                    <Text color="#ff0000" fontSize="12px" py="5px">
                      {errors.email && errors.email?.message}
                    </Text>
                  </Box>
                  <Box mb="40px">
                    <FormLabel
                      htmlFor="subject"
                      color="#161C2D"
                      fontSize="14px"
                      fontWeight="700"
                      fontFamily="Inter"
                    >
                      Subject
                    </FormLabel>
                    <Input
                      {...register('subject', {
                        required: 'Enter your subject',
                      })}
                      id="subject"
                      type="text"
                      borderColor={errors.subject && '#ff0000'}
                    />
                    <Text color="#ff0000" fontSize="12px" py="5px">
                      {errors.subject && errors.subject?.message}
                    </Text>
                  </Box>
                  <Box mb="40px">
                    <FormLabel
                      htmlFor="message"
                      color="#161C2D"
                      fontSize="14px"
                      fontWeight="700"
                      fontFamily="Inter"
                    >
                      Your message (optional)
                    </FormLabel>
                    <Textarea  {...register('message')} id="message" />
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <Button
                      type="submit"
                      background="#473BF0"
                      w="100%"
                      color="#ffffff"
                      py="30px"
                      px="30px"
                      fontFamily="Inter"
                      fontWeight="500"
                      fontSize="16px"
                      colorScheme="#473BF0"
                    >
                      Submit
                    </Button>
                  </Box>
                </FormControl>
              </form>
            </Box>
            <Box
              display="flex"
              justifyContent=""
              maxW={['100%', '100%', '50%', '30%']}
              px="15px"
              pb={['30px', '30px', '0']}
              flexDirection={['column']}
            >
              {contact.map((el, idx) => (
                <Box
                  key={idx}
                  display="flex"
                  w={['100%', '100%', '100%', '90%']}
                  my={['20px', '30px', '40px']}
                  className="temir_text"
                >
                  <Box
                    bg="#473BF0"
                    width="35px"
                    height="35px"
                    borderRadius="50%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    cursor="pointer"
                    p="10px"
                    onClick={() => {
                      if (el.link) {
                        window.location.href = el.link
                      }
                    }}
                  >
                    {el.icon}
                  </Box>
                  <Box paddingLeft="2">
                    <Text
                      color="dark"
                      fontFamily="Inter"
                      fontSize="22px"
                      fontWeight="700"
                    >
                      {el.theme}
                    </Text>
                    <Text
                      color={idx === 1 ? '#473BF0' : '#7A7A7A'}
                      fontFamily="Inter"
                      fontSize="18px"
                      cursor={`${
                        idx === 0 ? 'pointer' : idx === 1 && 'pointer'
                      }`}
                      onClick={() => {
                        if (el.link) {
                          window.location.href = el.link
                        }
                      }}
                    >
                      {el.messenger}
                    </Text>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default ContactUs
