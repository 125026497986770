import React, { useState } from "react";
import { Box, Button, Container, Image, Text } from "@chakra-ui/react";

import Logo from "../../assets/image/logodis.png.png";
import "./style.scss";
import MobileMenu from "./MobileMenu";
import { Link, NavLink, useNavigate } from "react-router-dom";

export default function Header() {
  const [openBurger, setOpenBurger] = useState(false);
  const navigate = useNavigate();
  const links = [
    {
      link: "/",
      text: "Home",
    },
    {
      link: "/dispatch-courses",
      text: "Dispatch Courses",
    },
    {
      link: "/join-us",
      text: "Join Us!",
    },
    {
      link: "/contact-us",
      text: "Contact Us",
    },
  ];

  return (
    <Box
      borderBottom="1px solid #C1C1C1"
      position="fixed"
      top="0"
      left="0"
      w="100vw"
      bg="white"
      zIndex="1560"
    >
      <Container maxW="1200px" py="15px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Link to="/">
              <Image src={Logo} maxW="120px" alt="logo" mr="40px" />
            </Link>
            <Box
              display={{ base: "none", lg: "flex" }}
              justifyContent="space-between"
            >
              {links.map((items, index) => (
                <NavLink
                  to={items.link}
                  key={index}
                  style={({ isActive }) =>
                    isActive ? { color: "#473BF0" } : { color: "#161C2D" }
                  }
                >
                  <Text
                    mx="35px"
                    fontWeight="700"
                    _hover={{ color: "#473BF0" }}
                    fontSize="14px"
                    key={index}
                  >
                    {items.text}
                  </Text>
                </NavLink>
              ))}
            </Box>
          </Box>

          <Button
            display={{ base: "none", lg: "block" }}
            bg="primary"
            colorScheme="primary"
            fontWeight="600"
            w="106.02px"
            h="51px"
            onClick={() => {
              navigate("/contact-us");
            }}
          >
            Contacts
          </Button>
          <button
            onClick={() => setOpenBurger(!openBurger)}
            className={`burger-button ${openBurger ? "active" : ""}`}
          >
            <Box
              w="26px"
              rounded="10px"
              m={{ l: "auto" }}
              h="2px"
              bg="#7E8085"
              className="burger-button-top"
            ></Box>
            <Box
              w="26px"
              rounded="10px"
              h="2px"
              bg="#7E8085"
              className="burger-button-bottom"
            ></Box>
          </button>
        </Box>
        <MobileMenu
          className={openBurger ? "transform" : ""}
          setOpenBurger={setOpenBurger}
        />
      </Container>
    </Box>
  );
}
