import { Box, Container, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import AOS from 'aos'
import 'aos/dist/aos.css'

const WeSpecialise = () => {
  const list = [
    {
      number: "1",
      title: "Dry Van",
    },
    {
      number: "2",
      title: "Reefer",
    },
    {
      number: "3",
      title: "Flatbed",
    },
    {
      number: "4",
      title: "Power Only",
    },
    {
      number: "5",
      title: "Box Trucks",
    },
  ];

   useEffect(() => {
     AOS.init()
   }, [])

  return (
    <section style={{ marginBottom: '70px' }}>
      <Container maxW="1200px" pt={{ base: '65px', xl: '120px' }}>
        <Text
          textAlign="center"
          pb="37px"
          fontSize={{ base: '24px', md: '34px' }}
          color="dark"
          fontWeight="700"
        >
          WE SPECIALISE
        </Text>

        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
        >
          {list.map((item, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              mb="34px"
              w="146px"
            >
              <Box
                w="43px"
                h="43px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                rounded="50%"
                bg="rgba(71, 59, 240, 0.11)"
                color="primary"
                fontWeight="700"
                fontSize="15.2px"
                mr="20px"
                data-aos-duration="1000"
                data-aos="zoom-in"
              >
                {item.number}
              </Box>
              <Text
                fontWeight="700"
                fontSize="14.2px"
                data-aos-duration="1000"
                data-aos="fade-left"
              >
                {item.title}
              </Text>
            </Box>
          ))}
        </Box>
      </Container>
    </section>
  )
};

export default WeSpecialise;
