import { Box, Button, Container, Text } from "@chakra-ui/react";
import { NavLink, useNavigate } from "react-router-dom";
import "./style.scss";

export default function MobileMenu({ className, setOpenBurger }) {
  const lists = [
    {
      link: '/',
      text: 'Home',
    },
    {
      link: '/dispatch-courses',
      text: 'Dispatch Courses',
    },
    {
      link: '/join-us',
      text: 'Join Us!',
    },
    {
      link: '/contact-us',
      text: 'Contact Us',
    },
  ]

  const navigate = useNavigate()

  return (
    <Box
      bg="white"
      h="100vh"
      left="0"
      right="0"
      className={`mobile-menu ${className}`}
      pos="fixed"
      zIndex="5"
      display="flex"
      justifyContent="center"
      flexDir="column"
      alignItems="end"
    >
      <Box
        w="100%"
        mt="-100px"
        display="flex"
        flexDir="column"
        justifyContent="end"
        alignItems="end"
        mx="auto"
      >
        {lists?.map((link, index) => (
          <Container key={index}>
            <NavLink
              to={link.link}
              style={({ isActive }) =>
                isActive ? { color: '#473BF0' } : { color: '#161C2D' }
              }
            >
              <Text
                key={index}
                className="mobile-menu-link"
                textAlign="end"
                borderBottom="1px solid rgba(126, 128, 133, 0.5)"
                onClick={()=>{
                    setOpenBurger(false)
                    window.scroll(0, 0)
                }}
              >
                {link.text}
              </Text>
            </NavLink>
          </Container>
        ))}
      </Box>
      <Container>
        <Box display="flex" justifyContent="center">
          <Button
            py="24px"
            px="20px"
            bg="primary"
            colorScheme="primary"
            fontWeight="600"
            fontSize="20px"
            onClick={() => {
              navigate('/contact-us')
              setOpenBurger(false)
            }}
          >
            Contact Us
          </Button>
        </Box>
      </Container>
    </Box>
  )
}
