import { Box, Container, Text } from "@chakra-ui/react";
import React from "react";

const OurService = () => {
  const list = [
    {
      number: "1",
      title:
        "We handle all necessary faxes and paperwork, including broker setup, insurance certificates and rate confirmations including route and pay negotiation.",
    },
    {
      number: "2",
      title:
        "See to that your wheels are always rolling throughout the working day and your gross revenue is higher than ever; with us, you can forget waiting long hours for your drivers to be dispatched on a trip;",
    },
    {
      number: "3",
      title:
        "You pick the areas where you prefer to drive, and we'll look at many load boards to choose the best reefer, flatbed, Dry Van, Box Truck loads.",
    },
    {
      number: "4",
      title:
        "See to that your wheels are always rolling throughout the working day and your gross revenue is higher than ever; with us, you can forget waiting long hours for your drivers to be dispatched on a trip;",
    },
    {
      number: "5",
      title:
        "Our dispatchers set up with the brokers. You don't have to deal with any follow-up. You'll work with a dedicated dispatcher who gets to know you and your routes.",
    },
    {
      number: "6",
      title:
        "Check brokers' daily rating and credit score, check out loads availability at pickup and warehouse open hours at delivery, appointments for delivery and pickup, plan out routes, provide comprehensive support in emergency cases like detention at delivery, overweight etc.",
    },
    {
      number: "7",
      title: "US DISPATCH Corporation provides reliable 24/7 dispatch services",
    },
    {
      number: "8",
      title:
        "You can try out our services with no contract, and if you're satisfied with the services rendered, we will be delighted to welcome you as our new customer.",
    },
    {
      number: "9",
      title:
        "You can try out our services with no contract, and if you're satisfied with the services rendered, we will be delighted to welcome you as our new customer.",
    },
  ];
  return (
    <section style={{ marginTop: "70px", marginBottom: "70px" }}>
      <Box bg="#F4F7FA" py="65px">
        <Container maxW="1200px">
          <Text
            textAlign="center"
            fontSize={{ base: "24px", md: "34px" }}
            color="dark"
            fontWeight="700"
            mb={{ base: "47px", md: "77px" }}
          >
            OUR SERVICE
          </Text>

          <Box display="flex" flexDir="column" maxW="1000px" mx="auto">
            {list.map((item, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="start"
                alignItems="center"
                mb="46px"
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  rounded="50%"
                  bg="rgba(71, 59, 240, 0.11)"
                  color="primary"
                  fontWeight="700"
                  fontSize="15.2px"
                  mr="20px"
                >
                  <Text
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    w="43px"
                    h="43px"
                  >
                    {item.number}
                  </Text>
                </Box>
                <Text fontWeight="400" fontSize="14.2px" lineHeight="25.5px">
                  {item.title}
                </Text>
              </Box>
            ))}
          </Box>
        </Container>
      </Box>
    </section>
  );
};

export default OurService;
