import { Box, Container, Image, SimpleGrid, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

import Track from '../../../assets/image/track.png'
import Suh from '../../../assets/image/Suh.png'
import SvgCheck from '../../../assets/svg/SvgCheck'

const DryVans = () => {
  useEffect(() => {
    AOS.init()
  }, [])

  const list = [
    {
      title: 'Maximum weight: 42,000 – 45,000 lbs.',
    },
    {
      title: 'Volume: 3,500 ft. Width: 96” – 102 “.',
    },
    {
      title: 'Maximum length: 48 ft. – 53 ft. Height: 12.5” – 13,5”.',
    },
    {
      title: 'Maximum pallets: 26.Tare weight: 14,780 lbs.',
    },
  ]

  return (
    <section style={{ marginBottom: '160px' }}>
      <Container maxW="1200px">
        <SimpleGrid
          mb="87.22px"
          columns={{ base: 1, md: 2 }}
          spacingX="40px"
          spacingY="20px"
          alignItems="center"
          justifyContent="center"
        >
          <Box>
            <Image
              src={Track}
              mb={{ base: '46px', md: '40px' }}
              data-aos="zoom-in"
              data-aos-duration="1000"
            />
            <Image src={Suh} data-aos="fade-right" data-aos-duration="1000" />
          </Box>
          <Box>
            <Text
              fontWeight="700"
              mb="33px"
              fontSize={{ base: '25px', md: '32px' }}
            >
              53 Dry Vans
            </Text>
            {list.map((item, index) => (
              <Box key={index} display="flex" alignItems="center" mb="38px">
                <Box w="21px" h="21px">
                  <SvgCheck />
                </Box>
                <Text
                  ml="9.8"
                  fontWeight="700"
                  fontSize="18px"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  {item.title}
                </Text>
              </Box>
            ))}
          </Box>
        </SimpleGrid>
        <Box maxW="1093px" mb="52px">
          <Text mb="16px" color="black" fontSize="19px">
            A 53′ dry van is a type of semi-trailer that’s fully enclosed to
            protect shipments from outside elements.
          </Text>
          <Text mb="16px" color="black" fontSize="19px">
            Designed to carry palletized, boxed or loose freight, dry vans
            aren’t temperature-controlled (unlike refrigerated “reefer” units)
            and can’t carry oversized shipments (unlike flatbed trailers).
          </Text>
          <Text mb="16px" color="black" fontSize="19px">
            Dry Vans are the most common truckload trailer on the road due to
            their versatility and they are always in high demand by our brokers
            and shippers.
          </Text>
        </Box>
        <Box>
          <Box display="flex" alignItems="center">
            <Box
              bg="#68D585"
              w="30px"
              h="30px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              fontWeight="700"
              rounded="50%"
              color="white"
              mx={{ base: '10px', md: '20px' }}
            >
              <Text
                display="flex"
                justifyContent="center"
                alignItems="center"
                w="30px"
                h="30px"
              >
                ?
              </Text>
            </Box>
            <Text fontWeight="700">
              What Are The Inside Dimensions of a Dry Van Trailer?
            </Text>
          </Box>
          <Text
            maxW="1000px"
            color="#7A7A7A"
            fontSize="14.2px"
            lineHeight="22.8px"
            ml={{ base: '50px', md: '70px' }}
          >
            Although these trailers come in various sizes, including 48 feet
            long, the 53-foot dry van trailer is by far the most common. These
            are the trailers you’ll use to move your palletized goods, and these
            are the trailers you see zooming along America’s highways most
            often.
            <br /> When it comes to their internal dimensional capacities,
            here’s what you should be aware of:
            <br />{' '}
            <span style={{ fontWeight: '700', color: '#161C2D' }}>Height</span>:
            The maximum cargo height capacity of a 53-foot dry van trailer falls
            between 108 and 110 inches (from 9 feet to 9 feet, 2 inches high)
            with 108 inches high being the most common.
            <br />{' '}
            <span style={{ fontWeight: '700', color: '#161C2D' }}>Width</span>:
            The maximum cargo width capacity of a 53-foot dry van trailer falls
            between 98 and 100 inches (from 8 feet, 2 inches to 8 feet, 4 inches
            wide) with 100 inches wide being the most common.
            <br />{' '}
            <span style={{ fontWeight: '700', color: '#161C2D' }}>Length</span>:
            The maximum cargo length of a 53-foot dry van trailer is 53 feet
            long (636 inches).
            <br />
            <span style={{ fontWeight: '700', color: '#161C2D' }}>Weight</span>:
            The maximum cargo weight of a 53-foot dry van trailer falls between
            42,000 and 45,000 pounds depending on the trailer and commodity in
            question.
            <br /> Because dry vans are an enclosed trailer type, any product
            that exceeds these dimensions can’t be moved in one. That said,
            these are valuable numbers to know as they directly dictate the
            total amount of pallets you’ll be able to fit into a dry van
            trailer.
          </Text>
        </Box>
      </Container>
    </section>
  )
}

export default DryVans
