import { Box, Button, Container, Text } from "@chakra-ui/react";
import React from "react";
import Slider from "react-slick";

import SvgArrowLine from "../../../assets/svg/SvgArrowLine";
import BgImgFrist from "../../../assets/image/HeroImagFrist.png";
import BgImgSecondary from "../../../assets/image/truckSecondary.jpg";
const Hero = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
  };

  return (
    <section style={{ position: "relative" }}>
      <Container
        pos="absolute"
        zIndex="5"
        maxW="1200px"
        display="flex"
        alignItems="center"
        h="100vh"
        top="0"
        left="0"
        right="0"
      >
        <Box
          maxW="440px"
          pt={{ base: "20px", md: "106px" }}
          className="temir_text"
        >
          <Text
            color="white"
            fontWeight="700"
            fontSize={{ base: "38px", md: "59px" }}
            pb="30px"
          >
            US DISPATCH CORPORATION
          </Text>
          <Text color="white" lineHeight="32px" fontSize="18px" mb="22px">
            We offer professional dispatch services to MC companies and Owner
            operators, providing the highest level of support and excellent
            customer service.
          </Text>
          <Text color="white" mb={{ base: "26px", md: "40px" }} fontSize="18px">
            Dispatch service from 3% of gross
          </Text>
          <Button
            fontWeight="500"
            py="25px"
            px="20px"
            bg="primary"
            w="163px"
            colorScheme="primary"
            rightIcon={<SvgArrowLine />}
            display="flex"
            justifyContent="space-between"
          >
            Contacts
          </Button>
        </Box>
      </Container>
      <Slider {...settings}>
        <Box
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundImage={BgImgFrist}
          backgroundRepeat="no-repeat"
          mt="90px"
          minH={["100vh"]}
        ></Box>
        <Box
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundImage={BgImgSecondary}
          backgroundRepeat="no-repeat"
          mt="90px"
          minH={["100vh"]}
        ></Box>
      </Slider>
    </section>
  );
};

export default Hero;
