export default function SvgArrowLine() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.38672 1.78711C6.05469 2.11914 6.08789 2.61719 6.38672 2.94922L10.4043 6.73438H0.875C0.410156 6.73438 0.078125 7.09961 0.078125 7.53125V8.59375C0.078125 9.05859 0.410156 9.39062 0.875 9.39062H10.4043L6.38672 13.209C6.08789 13.541 6.08789 14.0391 6.38672 14.3711L7.11719 15.1016C7.44922 15.4004 7.94727 15.4004 8.24609 15.1016L14.7207 8.62695C15.0195 8.32812 15.0195 7.83008 14.7207 7.49805L8.24609 1.05664C7.94727 0.757812 7.44922 0.757812 7.11719 1.05664L6.38672 1.78711Z"
        fill="white"
      />
    </svg>
  );
}
