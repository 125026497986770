import { Box, Container, Image, SimpleGrid, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

import FristReefer from '../../../assets/image/Reefer.png'
import SecobdaryReefer from '../../../assets/image/ReeferSecondary.png'
import Reefer3 from '../../../assets/image/reefer3.png'

const Trailer = () => {
    
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <section>
      <Container maxW="1200px">
        <SimpleGrid
          columns={{ base: 0, md: 2 }}
          justifyContent="space-between"
          alignItems="center"
          py="10px"
        >
          <Box maxW="550px" mx={{ base: 'auto', md: '0' }}>
            <Image
              src={FristReefer}
              mb="40px"
              data-aos="fade-right"
              data-aos-duration="1000"
            />
            <Image
              src={SecobdaryReefer}
              mb="40px"
              data-aos="zoom-in"
              data-aos-duration="1000"
            />
            <Image
              src={Reefer3}
              data-aos="fade-right"
              data-aos-duration="1000"
            />
          </Box>
          <Box>
            <Box mb={{ base: '30px', md: '52px' }}>
              <Box display="flex" alignItems="center" mb="16px">
                <Box
                  bg="#68D585"
                  w="30px"
                  h="30px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  fontWeight="700"
                  rounded="50%"
                  color="white"
                  mx={{ base: '10px', md: '20px' }}
                >
                  <Text
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    w="30px"
                    h="30px"
                  >
                    ?
                  </Text>
                </Box>
                <Text
                  fontWeight="700"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  How many 40×48 pallets can you load in a 53 trailer?
                </Text>
              </Box>
              <Text
                maxW="1000px"
                color="#7A7A7A"
                fontSize="14.2px"
                lineHeight="23px"
                ml={{ base: '50px', md: '70px' }}
                data-aos="zoom-in"
                data-aos-duration="1000"
              >
                If your pallets are standard-sized (48″ x 40″ x 48″): A 53ft
                truck can fit
                <br /> 26 non-stackable pallets and 52 stackable, standard-size
                pallets.
                <br /> How much weight can a reefer trailer carry?
                <br /> A refrigerated or reefer trailer’s maximum freight weight
                is 44,000 pounds.
              </Text>
            </Box>
            <Box mb={{ base: '30px', md: '52px' }}>
              <Box display="flex" alignItems="center" mb="16px">
                <Box
                  bg="#68D585"
                  w="30px"
                  h="30px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  fontWeight="700"
                  rounded="50%"
                  color="white"
                  mx={{ base: '10px', md: '20px' }}
                >
                  <Text
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    w="30px"
                    h="30px"
                  >
                    ?
                  </Text>
                </Box>
                <Text
                  fontWeight="700"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  How many pallets fit in a 20ft reefer container UK?
                </Text>
              </Box>
              <Text
                maxW="1000px"
                color="#7A7A7A"
                fontSize="14.2px"
                lineHeight="23px"
                ml={{ base: '50px', md: '70px' }}
                data-aos="zoom-in"
                data-aos-duration="1000"
              >
                As it is shown on the above figure it is possible to fit 9 pcs
                of industrial pallets and 10 pcs of euro pallets in to a 20′
                reefer container.
              </Text>
            </Box>
            <Box mb={{ base: '30px', md: '39px' }}>
              <Box display="flex" alignItems="center" mb="16px">
                <Box
                  bg="#68D585"
                  w="30px"
                  h="30px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  fontWeight="700"
                  rounded="50%"
                  color="white"
                  mx={{ base: '10px', md: '20px' }}
                >
                  <Text
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    w="30px"
                    h="30px"
                  >
                    ?
                  </Text>
                </Box>
                <Text
                  fontWeight="700"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  What is a 53 ft reefer?
                </Text>
              </Box>
              <Text
                maxW="1000px"
                color="#7A7A7A"
                fontSize="14.2px"
                lineHeight="23px"
                ml={{ base: '50px', md: '70px' }}
                data-aos="zoom-in"
                data-aos-duration="1000"
              >
                The Cooling Concept’s 53-foot single-temp refrigerated trailer
                Multi-Temp is designed to haul products that require various
                temperatures and is the largest trailer we have. It is great for
                maintaining a single temperature and for those long country/
                statewide long hauls.
              </Text>
            </Box>
          </Box>
        </SimpleGrid>
      </Container>
    </section>
  )
}

export default Trailer
