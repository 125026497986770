import React, { useEffect } from 'react'
import { Box, Container, Text } from '@chakra-ui/layout'
import { Image } from '@chakra-ui/image'
import AOS from 'aos'
import 'aos/dist/aos.css'

import Fletbed from '../../../assets/image/fletbed.png.png'
import Flet from '../../../assets/image/flet-2.png.png'
import { FaQuestion } from 'react-icons/fa'

const Flatbed = () => {
  const text1 = [
    {
      quation: 'What is Flatbed Trucking?',
      desc1:
        'Flatbed trucks are, well, flat. With no walls or roofs, these trailers are open to the air and elements. The flatbed trailer consists of a wooden platform supported by a strong aluminum frame. While the decks are flat, some trailers carry drop decks, as well. Measuring anywhere from 48 feet to 53 feet long and spanning 8 feet, 6 inches wide, flatbed trailers have a deck height of 60 inches, and a weight capacity of up to 48,000 pounds.',
      desc2:
        'Because they are open, flatbed trucking offers a lot of logistics options, especially when it comes to heavy cargo. And depending on what part of the country you’re in, you could also see multiple flatbed trailers chained together, carrying large cargo.',
    },
    {
      quation: 'What are the typical dimensions of a flatbed trailer?',
      desc1:
        'As it is shown on the above figure it is possible to fit 9 pcs of industrial pallets and 10 pcs of euro pallets in to a 20′ reefer container.',
    },
  ]

  const text2 = [
    {
      quation: 'Are tarps required for all shipments?',
      desc: 'The process to load flatbed shipments is dependent upon the particulars of the shipping location and the type of cargo being handled. If your goods require the use of a crane for loading, take steps to ensure the appropriate equipment is available when your truck arrives for the most efficient operation.',
    },
    {
      quation: 'How are products loaded and unloaded onto flatbeds?',
      desc: 'decks, double drops and RGNs are some of the most common flatbed trailers used for shipping.',
    },
  ]

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <Box>
      <Container maxW="1170px" mx="auto">
        <Box w="100%" py="40px">
          <Box w="100%" display="flex" flexWrap="wrap">
            <Box w={['100%', '100%', '50%']}>
              <Text
                fontFamily="Inter"
                color="dark"
                fontSize={['26px', '33px']}
                fontWeight="700"
              >
                Flatbed
              </Text>
              <Box>
                {text1.map((el, idx) => (
                  <Box
                    key={idx}
                    data-aos={idx === 0 ? 'fade-left' : 'fade-right'}
                    data-aos-duration="1000"
                  >
                    <Box display="flex" alignItems="center" mt="40px">
                      <Box
                        alignItems="center"
                        justifyContent="center"
                        background="#68D585"
                        borderRadius="50%"
                        w="30px"
                        h="30px"
                        display="flex"
                        p="10px"
                      >
                        <FaQuestion fontWeight="900" color="#ffffff" />
                      </Box>
                      <Text
                        fontFamily="Inter"
                        fontWeight="700"
                        fontSize="19px"
                        px={['10px', '20px']}
                      >
                        {el.quation}
                      </Text>
                    </Box>
                    <Text
                      fontFamily="Inter"
                      fontSize="15px"
                      fontWeight="400"
                      color="#7A7A7A"
                      mt="20px"
                      pl={['40px', '50px']}
                      pr={['10px', '20px']}
                    >
                      {el.desc1}
                    </Text>
                    <Text
                      fontFamily="Inter"
                      fontSize="15px"
                      fontWeight="400"
                      color="#7A7A7A"
                      mt={'20px'}
                      pl={['40px', '50px']}
                      pr={['10px', '20px']}
                    >
                      {el?.desc2}
                    </Text>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              w={['100%', '100%', '50%']}
              display="flex"
              flexDirection="column"
              //   alignItems="center"
              justifyContent="center"
            >
              <Image
                src={Fletbed}
                maxW={['95%', '95%', '95%', '95%', '95%', '550px']}
                mx="auto"
                mb={['10px', '20px', '40px', '1px']}
                data-aos="zoom-in-right"
                data-aos-duration="1000"
              />
              <Image
                src={Flet}
                maxW={['90%', '90%', '90%', '90%', '90%', '450px']}
                mx={['auto', 'auto', '12px']}
                mt={['10px', '20px', '40px', '20px']}
                data-aos="zoom-in-right"
                data-aos-duration="1000"
              />
            </Box>
          </Box>
          <Box display="flex" w="100%" flexWrap="wrap">
            {text2.map((elem, idx) => (
              <Box
                key={idx}
                w={['100%', '100%', '50%']}
                justifyContent="space-between"
                data-aos={idx === 0 ? 'fade-left' : 'fade-right'}
                data-aos-duration="1000"
              >
                <Box display="flex" alignItems="center" mt="40px">
                  <Box
                    alignItems="center"
                    justifyContent="center"
                    background="#68D585"
                    borderRadius="50%"
                    w="30px"
                    h="30px"
                    display="flex"
                    p="10px"
                  >
                    <FaQuestion fontWeight="900" color="#ffffff" />
                  </Box>
                  <Text
                    fontFamily="Inter"
                    fontWeight="700"
                    fontSize="19px"
                    px={['10px', '20px']}
                  >
                    {elem.quation}
                  </Text>
                </Box>
                <Text
                  fontFamily="Inter"
                  fontSize="15px"
                  fontWeight="400"
                  color="#7A7A7A"
                  mt="20px"
                  pl={['40px', '50px']}
                  pr={['10px', '20px']}
                >
                  {elem.desc}
                </Text>
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Flatbed
