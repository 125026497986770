export default function SvgTelegram() {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4219 1.71094C18.6562 0.617188 18.0312 0.1875 17.3281 0.460938L1.78125 6.4375C0.726562 6.86719 0.765625 7.45312 1.625 7.72656L5.57031 8.9375L14.7891 3.15625C15.2188 2.84375 15.6484 3.03906 15.2969 3.3125L7.83594 10.0312L7.5625 14.1328C7.99219 14.1328 8.14844 13.9766 8.38281 13.7422L10.2969 11.8672L14.3203 14.8359C15.0625 15.2656 15.6094 15.0312 15.8047 14.1719L18.4219 1.71094Z"
        fill="#304C6D"
      />
    </svg>
  );
}
