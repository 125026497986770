import React from 'react'
import { Box, Spinner } from '@chakra-ui/react'

const LoadingPage = () => {
  return (
    <Box
      w="100vw"
      h="90vh"
      bg="white"
      position="fixed"
      top="0"
      left="0"
      zIndex="1500"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        w="100%"
        h="100%"
      >
        <Spinner thickness="3px" speed="0.65s" size="lg" color="#473BF0" />
      </Box>
    </Box>
  )
}

export default LoadingPage
