export default function SvgLinkedin() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.85 0.015625H1.31484C0.717187 0.015625 0.225 0.542969 0.225 1.17578V14.6406C0.225 15.2734 0.717187 15.7656 1.31484 15.7656H14.85C15.4477 15.7656 15.975 15.2734 15.975 14.6406V1.17578C15.975 0.542969 15.4477 0.015625 14.85 0.015625ZM4.97109 13.5156H2.65078V6.02734H4.97109V13.5156ZM3.81094 4.97266C3.0375 4.97266 2.43984 4.375 2.43984 3.63672C2.43984 2.89844 3.0375 2.26562 3.81094 2.26562C4.54922 2.26562 5.14687 2.89844 5.14687 3.63672C5.14687 4.375 4.54922 4.97266 3.81094 4.97266ZM13.725 13.5156H11.3695V9.85938C11.3695 9.01562 11.3695 7.89062 10.1742 7.89062C8.94375 7.89062 8.76797 8.83984 8.76797 9.82422V13.5156H6.44766V6.02734H8.6625V7.04688H8.69766C9.01406 6.44922 9.7875 5.81641 10.9125 5.81641C13.268 5.81641 13.725 7.39844 13.725 9.40234V13.5156Z"
        fill="#7D818D"
      />
    </svg>
  );
}
