import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import LoadForm from '../../loading/LoadForm'
import BackdropExample from '../../modal/modalApi'
import axios from 'axios'

const FreightBrokers = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  )

  const [load, setLoad] = useState(false)
  const [overlay, setOverlay] = useState(<OverlayOne />)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [status, setStatus] = useState(false)
  const [text, setText] = useState('')

  const OpenModal = () => {
    setOverlay(<OverlayOne />)
    onOpen()
    setLoad(false)
  }

  function onSubmit(values) {
    setLoad(true)
    return new Promise((resolve) => {
      axios
        .post('https://usdiscorp.pythonanywhere.com/usdispatch/Freight-brokers/', {
          ...values,
        })
        .then(({ data }) => {
          setStatus(true)
          setText('Data sent successfully!')
          OpenModal()
        })
        .catch((err) => {
          setStatus(false)
          setText(`An error occurred while sending data! ${err.message}`)
          OpenModal()
        })

      resolve()
    })
  }
  return (
    <Box bg="#F4FCFF">
      <Container maxW="1170px" mx="auto" py="50px">
        <BackdropExample
          isOpen={isOpen}
          onClose={onClose}
          overlay={overlay}
          status={status}
          text={text}
        />
        <Box
          w="100%"
          alignItems="flex-start"
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Box maxW={['100%', '80%', '50%']} mx="auto">
            <Text
              color="dark"
              fontFamily="Inter"
              fontWeight="700"
              fontSize={['38px', '48px', '58px']}
            >
              FREIGHT BROKERS
            </Text>
            <Text
              color="dark"
              fontFamily="Inter"
              fontWeight="400"
              fontSize="17px"
              mt="20px"
            >
              It takes BOTH sides to move a load.
            </Text>
            <Text
              color="dark"
              fontFamily="Inter"
              fontWeight="700"
              fontSize="19px"
              mt="10px"
              mb="30px"
            >
              We live to our slogan, updates guaranteed while over 300 drivers
              move your freight.
            </Text>
          </Box>
          <Box w={['100%', '100%', '50%']}>
            <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
              <FormControl
                maxW={['100%', '100%', '360px', '450px', '530px']}
                background="#FFFFFF"
                border="1px solid #C1C1C1"
                rounded="10px"
                py="30px"
                mx="auto"
                position="relative"
                px="20px"
              >
                {load && <LoadForm />}
                <Box
                  mb="30px"
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={['column', 'row']}
                  w="100%"
                >
                  <Box w={['100%', '48%']} mb={['30px', '0']}>
                    <FormLabel
                      color="#888888"
                      fontSize="16px"
                      fontWeight="400"
                      fontFamily="Inter"
                    >
                      <Input
                        {...register('username', {
                          required: 'Enter your name',
                        })}
                        borderBottom={errors.username && '1px solid #ff0000'}
                        type="text"
                        variant="flushed"
                        placeholder="Name*"
                        color="dark"
                        fontFamily="Inter"
                        fontSize="15px"
                      />
                      <Text color="#ff0000" fontSize="12px" fontWeight="600">
                        {errors.username && errors.username?.message}
                      </Text>
                    </FormLabel>
                  </Box>
                  <Box w={['100%', '48%']}>
                    <FormLabel
                      color="#161C2D"
                      fontSize="14px"
                      fontWeight="700"
                      fontFamily="Inter"
                    >
                      <Input
                        {...register('email', {
                          required: 'Enter your email',
                          pattern: {
                            value:
                              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                            message: 'Enter a valid email',
                          },
                        })}
                        type="email"
                        variant="flushed"
                        borderBottom={errors.email && '1px solid #ff0000'}
                        placeholder="Email Address*"
                        color="dark"
                        fontFamily="Inter"
                        fontSize="15px"
                      />
                      <Text color="#ff0000" fontSize="12px" fontWeight="600">
                        {errors.email && errors.email?.message}
                      </Text>
                    </FormLabel>
                  </Box>
                </Box>
                <Box mb="30px">
                  <FormLabel
                    color="Phone"
                    fontSize="14px"
                    fontWeight="700"
                    fontFamily="Inter"
                  >
                    <Input
                      {...register('phone', { required: 'Enter your phone' })}
                      type="tel"
                      variant="flushed"
                      placeholder="Phone*"
                      borderBottom={errors.phone && '1px solid #ff0000'}
                      color="dark"
                      fontFamily="Inter"
                      fontSize="15px"
                    />
                    <Text color="#ff0000" fontSize="12px" fontWeight="600">
                      {errors.phone && errors.phone?.message}
                    </Text>
                  </FormLabel>
                </Box>
                <Box mb="30px">
                  <FormLabel
                    color="#888888"
                    fontSize="14px"
                    fontWeight="700"
                    fontFamily="Inter"
                  >
                    <Input
                      {...register('lane')}
                      type="text"
                      variant="flushed"
                      placeholder="Your Lane"
                      color="dark"
                      fontFamily="Inter"
                      fontSize="15px"
                    />
                  </FormLabel>
                </Box>
                <Box mb="30px">
                  <FormLabel
                    color="#888888"
                    fontSize="14px"
                    fontWeight="700"
                    fontFamily="Inter"
                  >
                    <Input
                      {...register('budget')}
                      type="text"
                      variant="flushed"
                      placeholder="Budget"
                      color="dark"
                      fontFamily="Inter"
                      fontSize="15px"
                    />
                  </FormLabel>
                </Box>
                <Box display="flex" justifyContent="flex-start">
                  <Button
                    type="submit"
                    background="#17A8E3"
                    color="#ffffff"
                    py="10px"
                    px="30px"
                    fontFamily="Inter"
                    fontWeight="500"
                    fontSize="16px"
                    colorScheme="#473BF0"
                  >
                    Submit
                  </Button>
                </Box>
              </FormControl>
            </form>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default FreightBrokers
