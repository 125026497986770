import { Box, Container, Text } from '@chakra-ui/layout'
import React, { useEffect } from 'react'
import { FaQuestion } from 'react-icons/fa'
import AOS from 'aos'
import 'aos/dist/aos.css'

const TruckLoad = () => {
  const text = [
    {
      question: 'How many pallets are in a truck load?',
      desc: 'Truckload: A full truckload shipment can range from 24 to 30 pallets and up. With truckload freight, the space your shipment takes up in the trailer has more of an impact than weight, so truckload shipments commonly range from 5,000 pounds to 45,000 pounds and up.',
    },
    {
      question: 'How wide is a 53 trailer?',
      desc: 'If we look at a 53 feet semi-trailer, dimensions vary ever so slightly between the overall and the interior. Overall length is 53 feet; overall width is 102″; and overall height is 13ft 6 inches. On the inside, semi-trailers are 47′ 6″ long, 98.5″ wide and 108 inches high.',
    },
    {
      question: 'How many standard pallets fit in a 26 truck?',
      desc: 'A 26″ box truck can fit in up to 12 pallets or 500 medium-sized boxes. It can also be used to transport about 15 medium-sized furniture items.',
    },
    {
      question: 'How many boxes will fit on a pallet?',
      desc: 'Approximately 700-1000 handable cartons (boxes). Box dimensions and stuffing plan may change total number of boxes loaded.',
    },
    {
      question: 'How many pallets fit in a 40 reefer?',
      desc: 'As it is shown on the above figure it is possible to fit 20 pcs of industrial pallets and 23 pcs of euro pallets in to a 40′ reefer high-cube container.',
      params: [
        {
          item: '32 F – 36 F',
        },
        {
          item: 'Greens, berries, apples, vegetables, grapes, stone fruits etc.',
        },
        {
          item: '38 F – 40 F',
        },
        {
          item: 'Avocado, cranberry, etc.',
        },
        {
          item: '40 F – 45 F',
        },
        {
          item: 'Potatoes, green beans, sweet potatoes, etc',
        },
        {
          item: '45 F – 50 F',
        },
        {
          item: 'Watermelon, cucumbers, citrus fruits, bananas, tomatoes, etc.',
        },
      ],
    },

    {
      question: 'How much weight can a 53 trailer hold?',
      desc: 'Maximum weight: 45,000 lbs can be hauled by a 53 feet trailer. Overall length:80” Total weight:80,000 lbs.',
    },
  ]

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <Box background="#F4F7FA">
      <Container maxW="1170px" mx="auto">
        <Box
          w="100%"
          pt="20px"
          pb="80px"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {text.map((elem, idx) => (
            <Box key={idx} w={['100%', '100%', '48%']}>
              <Box display="flex" alignItems="center" mt="40px">
                <Box
                  alignItems="center"
                  justifyContent="center"
                  background="#68D585"
                  borderRadius="50%"
                  w="30px"
                  h="30px"
                  display="flex"
                  p="10px"
                >
                  <FaQuestion fontWeight="900" color="#ffffff" />
                </Box>
                <Text
                  fontFamily="Inter"
                  fontWeight="700"
                  fontSize="19px"
                  px={['10px', '20px']}
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  {elem.question}
                </Text>
              </Box>
              <Text
                fontFamily="Inter"
                fontSize="15px"
                fontWeight="400"
                color="#7A7A7A"
                mt="20px"
                pl={['40px', '50px']}
                pr={['10px', '20px']}
                data-aos="zoom-in-up"
                data-aos-duration="1000"
              >
                {elem.desc}
              </Text>
              <Box>
                {elem?.params?.map((el, idx) => (
                  <Text
                    key={idx}
                    color="#404040"
                    fontFamily="Inter"
                    fontSize="15px"
                    fontWeight="400"
                    pl={['50px', '60px', '70px']}
                    pr={['10px', '20px']}
                    data-aos="zoom-in-right"
                    data-aos-duration="1000"
                  >
                    {el.item}
                  </Text>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  )
}

export default TruckLoad
