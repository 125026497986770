import { Box, Spinner } from '@chakra-ui/react'
import React from 'react'

const LoadForm = () => {
  return (
    <Box
      backdropBlur="2xl"
      position="absolute"
      top="0"
      bottom="0"
      left="0"
      right="0"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bg="#ffffff"
      zIndex="10"
      boxShadow="base"
      borderRadius="10px"
    >
      <Spinner thickness="3px" speed="0.65s" size="lg" color="#473BF0" />
    </Box>
  )
}

export default LoadForm
