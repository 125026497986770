import { Box, Container, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import AOS from 'aos'
import 'aos/dist/aos.css'

const WeProvide = () => {
  const list = [
    {
      number: "1",
      title: "Dispatching",
    },
    {
      number: "2",
      title: "Excellent Customer Service",
    },
    {
      number: "3",
      title: "Updator",
    },
    {
      number: "4",
      title: "Best Rate negotiation",
    },
    {
      number: "5",
      title: "Route planning",
    },
    {
      number: "6",
      title: "Factoring service",
    },
  ];

   useEffect(() => {
     AOS.init()
   }, [])

  return (
    <section style={{ marginTop: '65px', marginBottom: '70px' }}>
      <Container maxW="1200px">
        <Text
          textAlign="center"
          pb="27px"
          fontSize={{ base: '24px', md: '34px' }}
          color="dark"
          fontWeight="700"
        >
          WHAT WE PROVIDE
        </Text>

        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent={{ base: 'center', sm: 'space-between' }}
        >
          {list.map((item, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              mb="34px"
              w="145px"
              mr="10px"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                rounded="50%"
                bg="rgba(71, 59, 240, 0.11)"
                color="primary"
                fontWeight="700"
                fontSize="15.2px"
                mr="20px"
                data-aos-duration="1000"
                data-aos="zoom-in"
              >
                <Text
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  w="43px"
                  h="43px"
                >
                  {item.number}
                </Text>
              </Box>
              <Text
                fontWeight="700"
                fontSize="14.2px"
                data-aos-duration="1000"
                data-aos="fade-left"
                pt={index === 1 ? '45px' : '0' && index > 2 && '25px'}
              >
                {item.title}
              </Text>
            </Box>
          ))}
        </Box>
      </Container>
    </section>
  )
};

export default WeProvide;
