export default function SvgCheck() {
  return (
    <svg
      width="22"
      height="17"
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75781 15.6875C8.14844 16.0781 8.8125 16.0781 9.20312 15.6875L20.6875 4.20312C21.0781 3.8125 21.0781 3.14844 20.6875 2.75781L19.2812 1.35156C18.8906 0.960938 18.2656 0.960938 17.875 1.35156L8.5 10.7266L4.08594 6.35156C3.69531 5.96094 3.07031 5.96094 2.67969 6.35156L1.27344 7.75781C0.882812 8.14844 0.882812 8.8125 1.27344 9.20312L7.75781 15.6875Z"
        fill="#473BF0"
      />
    </svg>
  );
}
