import { Box, Button, Container, Text } from "@chakra-ui/react";
import React from "react";
import SvgTelegram from "../../../assets/svg/SvgTelgram";

const TruckDispatcher = () => {
  return (
    <section>
      <Box bg="#F4FCFF" mt={["","50px"]}>
        <Container maxW="1200px">
          <Text
            maxW="400px"
            color="#2F4B6E"
            mb="23px"
            fontSize="48px"
            fontWeight="700"
            pt="162px"
            className="temir_text"
          >
            Truck dispatcher Course
          </Text>
          <Text
            color="#2F4B6E"
            mb="31px"
            fontSize="21px"
            fontWeight="400"
            lineHeight="32px"
            maxW="425px"
            className="temir_text"
          >
            Hello and welcome to Goal Training Dispatch Academy
          </Text>
          <Button
            className="temir_text"
            leftIcon={<SvgTelegram />}
            w="255.39px"
            bg="#F8E587"
            colorScheme="#F8E587"
            color="#2F4B6E"
            textTransform="uppercase"
            h="54px"
            fontSize="16px"
            rounded="31px"
            display="flex"
            justifyContent="space-around"
          >
            Leave a request
          </Button>

          <Text
            pt={{ base: '80px', md: '147px' }}
            pb={{ base: '94px', md: '75px' }}
            color="#2F4B6E"
            lineHeight="32px"
            mx="auto"
            maxW="1112px"
            fontSize="21px"
            textAlign="center"
          >
            Goal Training- invites all interested candidates to take the
            Dispatcher training Course. We offer official truck dispatcher
            course online. Our dispatch training make you to offer better level
            to provide service to your future employments. We teach from the
            beginning level until progressive level. After you finish this
            course, you will learn all aspects of the trucking industry and
            dispatching business. We will provide you with basic understanding
            of the industry and show you how to book your first load. First we
            will be teaching basics of dispatching and the main responsibilities
            of the dispatcher, what dispatcher has to do on a workday to get the
            trucks moving and documentations etc. Later we will be teaching you
            how to use different kind of load boards etc. You will be fully
            prepared to start up your own venture. In our program you will get
            theoretical and real practical lessons. You are able to get real
            experience from our dispatcher working together. Our program lasts 2
            months. 1st month will be theoretical lessons, 2nd month You will
            get practical lesson. We will bring you closer to complete financial
            independence.
          </Text>
        </Container>
      </Box>
    </section>
  )
};

export default TruckDispatcher;
