import React from "react";
import { Box, Container, Text } from "@chakra-ui/react";

const GetStarted = () => {
  return (
    <Box background="dark">
      <Container maxW="1170px" mx="auto">
        <Box w="100%" pt="40px" pb="10px">
          <Box
            background="rgba(255,255,255,0.08)"
            w="60px"
            h="60px"
            borderRadius="50%"
          ></Box>
          <Text
            color="#ffffff"
            fontFamily="Inter"
            fontWeight="700"
            fontSize="36px"
            maxW="300px"
            my="20px"
            lineHeight="38px"
          >
            IT’S EASY TO GET STARTED
          </Text>
          <Text
            color="rgba(255,255,255,0.65)"
            fontFamily="Inter"
            fontWeight="400"
            fontSize="18px"
            mb="20px"
          >
            CONTACT US TODAY
          </Text>
          <Text
            color="rgba(255,255,255,0.65)"
            fontFamily="Inter"
            fontWeight="400"
            fontSize="18px"
            mb="20px"
          >
            We look forward to working together!
          </Text>
          <Text
            color="rgba(255,255,255,0.65)"
            fontFamily="Inter"
            fontWeight="400"
            fontSize="18px"
            mb="20px"
          >
            24/7 Road Side Assistance & Logistics Support
          </Text>
        </Box>
      </Container>
    </Box>
  );
};

export default GetStarted;
