export default function SvgInstagram() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2875 0.015625H1.9125C0.963281 0.015625 0.225 0.789062 0.225 1.70312V14.0781C0.225 15.0273 0.963281 15.7656 1.9125 15.7656H6.72891V10.4219H4.51406V7.89062H6.72891V5.99219C6.72891 3.8125 8.02969 2.58203 9.99844 2.58203C10.9828 2.58203 11.9672 2.75781 11.9672 2.75781V4.90234H10.8773C9.7875 4.90234 9.43594 5.57031 9.43594 6.27344V7.89062H11.8617L11.475 10.4219H9.43594V15.7656H14.2875C15.2016 15.7656 15.975 15.0273 15.975 14.0781V1.70312C15.975 0.789062 15.2016 0.015625 14.2875 0.015625Z"
        fill="#7D818D"
      />
    </svg>
  );
}
