import React, { useEffect } from 'react'
import { Box, Container, Text } from '@chakra-ui/react'
import { BsTelephoneFill } from 'react-icons/bs'
import { FaEnvelope } from 'react-icons/fa'
import { MdLocationOn } from 'react-icons/md'
import AOS from 'aos'
import 'aos/dist/aos.css'

const ContactUsHome = () => {
  const contact = [
    {
      icon: <BsTelephoneFill color="#ffffff" fontSize="15px" />,
      theme: 'Call us',
      link: 'tel:+1 815 907 1273',
      messenger: '+1 815 907 1273',
    },
    {
      icon: <FaEnvelope color="#ffffff" fontSize="15px" />,
      theme: 'Text Us',
      link: 'mailto:dispatch@usdiscorp.com',
      messenger: 'dispatch@usdiscorp.com',
    },
    {
      icon: <MdLocationOn color="#ffffff" fontSize="20px" />,
      theme: 'Visit us',
      messenger: '2425 W BRYN MAWR AVE, CHICAGO, IL 60659',
    },
  ]

  useEffect(() => {
    AOS.init({
      easing: 'ease',
    })
  }, [])

  return (
    <Box bg="#F4F7FA">
      <Container maxW="1200px">
        <Box maxW="1170px" mx="auto" py={['20px', '35px', '50px']}>
          <Text
            color="dark"
            fontFamily="Roboto, sans-serif"
            fontSize={['40px', '55px', '60px']}
            textAlign="center"
            fontWeight="700"
          >
            Contact us
          </Text>
          <Text
            color="#6E727D"
            fontFamily="Roboto"
            fontSize="19px"
            textAlign="center"
            fontWeight="400"
            my={['1 0px', '25px', '30px']}
          >
            Feel free to contact us for any business or personal inquiries
          </Text>
          <Box
            display="flex"
            justifyContent="space-evenly"
            w="100%"
            flexDirection={['column', 'column', 'row']}
          >
            {contact.map((el, idx) => (
              <Box
                key={idx}
                display="flex"
                alignItems="flex-start"
                w={['100%', '100%', '30%']}
                my="20px"
                data-aos={
                  idx === 0 ? 'fade-right' : idx === 2 ? 'fade-left' : 'zoom-in'
                }
                data-aos-duration="1000"
              >
                <Box
                  bg="#473BF0"
                  width="50px"
                  height="50px"
                  borderRadius="50%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  cursor="pointer"
                  p="15px"
                  onClick={() => {
                    if (el.link) {
                      window.location.href = el.link
                    }
                  }}
                >
                  {el.icon}
                </Box>
                <Box paddingLeft="2">
                  <Text
                    color="dark"
                    fontFamily="Inter"
                    fontSize="22px"
                    fontWeight="700"
                  >
                    {el.theme}
                  </Text>
                  <Text
                    color={idx === 1 ? '#473BF0' : '#7A7A7A'}
                    fontFamily="Inter"
                    fontSize="18px"
                    cursor={`${idx === 0 ? 'pointer' : idx === 1 && 'pointer'}`}
                    onClick={() => {
                      if (el.link) {
                        window.location.href = el.link
                      }
                    }}
                  >
                    {el.messenger}
                  </Text>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default ContactUsHome
