import React, { useEffect } from 'react'
import { Box, Container, Text } from '@chakra-ui/layout'
import AOS from 'aos'
import 'aos/dist/aos.css'

const OtherBenefits = () => {
  const texts = [
    {
      theme: 'For 1 driver will assist 2 dispatchers, updator, supervisor',
      desc: 'Dispatch support 24 hours per day 7 days a week',
    },
    {
      theme: 'Pay for Result Only',
      desc: 'Our commission based dispatch service and our payment depends on our results',
    },
    {
      theme: 'Dispatch Service',
      desc: 'Handle all necessary faxes and paperwork, including broker setup, insurance certificates and rate confirmations',
    },
  ]

  useEffect(() => {
    AOS.init({
      easing: 'ease',
    })
    AOS.refresh()
  }, [])

  return (
    <Box>
      <Container maxW="1140px" mx="auto">
        <Box w="100%" py={['20px', '40px', '60px']}>
          <Text
            fontFamily="Roboto,  sans-serif"
            fontWeight="700"
            textAlign="center"
            fontSize={['34px', '40px', '48px']}
          >
            OTHER BENEFITS
          </Text>
          <Text
            fontFamily="Inter"
            fontWeight="400"
            fontSize="17px"
            color="#7A7A7A"
            textAlign="center"
            my="20px"
          >
            Feel free to contact us for any business or personal inquiries
          </Text>
          <Box
            w="100%"
            display="flex"
            flexDirection={['column', 'column', 'row']}
            alignItems="start"
            justifyContent="space-between"
          >
            {texts.map((elem, idx) => (
              <Box
                w={['80%', '80%', '25%']}
                key={idx}
                data-aos={
                  idx === 0 ? 'fade-right' : idx === 2 ? 'fade-left' : 'zoom-in'
                }
                data-aos-duration="1000"
              >
                <Text
                  color="dark"
                  fontFamily="Inter"
                  fontWeight="700"
                  fontSize={['20px', '24px']}
                >
                  {elem.theme}
                </Text>
                <Text
                  fontFamily="Inter"
                  fontWeight="400"
                  fontSize="17px"
                  color="#7A7A7A"
                  textAlign="left"
                  my="20px"
                >
                  {elem.desc}
                </Text>
              </Box>
            ))}
          </Box>
          <Text
            maxW="503px"
            mx="auto"
            fontFamily="Inter"
            fontWeight="400"
            fontSize="17px"
            color="#7A7A7A"
            textAlign="center"
            my="20px"
          >
            We control your dispatch giving you opportunity and time to
            concentrate on more important things in life.
          </Text>
        </Box>
      </Container>
    </Box>
  )
}

export default OtherBenefits
