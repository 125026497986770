import React, { useEffect, useState } from 'react'
import {
  BoxTruck,
  ContactUsHome,
  DryVans,
  Flatbed,
  HeroHome,
  LoadingPage,
  OtherBenefits,
  OurService,
  PowerOnly,
  Trailer,
  TruckLoad,
  WeProvide,
  WeSpecialise,
} from '../../components'

const HomePage = () => {
  const [load, setLoad] = useState(true)
  useEffect(() => {
    setLoad(false)
  }, [])
  return (
    <>
      {load ? (
        <LoadingPage />
      ) : (
        <>
          <HeroHome />
          <WeSpecialise />
          <WeProvide />
          <OurService />
          <DryVans />
          <PowerOnly />
          <Trailer />
          <TruckLoad />
          <Flatbed />
          <BoxTruck />
          <OtherBenefits />
          <ContactUsHome />
        </>
      )}
    </>
  )
}

export default HomePage
