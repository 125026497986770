import { Box, Container, Image, SimpleGrid, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

import PowerOnlyLoads from '../../../assets/image/POWER-ONLY2.png'
import PowerTrack from '../../../assets/image/POWER-TRACK.png'

const PowerOnly = () => {
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <section>
      <Box bg="#F4F7FA" pt="55px" py="93px">
        <Container maxW="1200px" mx="auto">
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            justifyContent="space-between"
            alignItems="center"
            mb="65px"
          >
            <Box maxW={{ base: '100%', md: '423px' }} mr="40px" mb="66px">
              <Text
                color="dark"
                fontWeight="700"
                mb={{ base: '66px', md: '55px' }}
                fontSize={{ base: '27px', md: '36px' }}
              >
                POWER ONLY
              </Text>
              <Text mb="16px" fontSize="19px" lineHeight="32px" color="#7A7A7A">
                Power only represents a particular branch of trucking service
                and operations. It refers to the use of a third-party carrier to
                provide trucking power for load shipments.
              </Text>
              <Text fontSize="19px" lineHeight="32px" color="#7A7A7A">
                With the truck provided, this is often referred to as power only
                as the trailers that carry the shipper’s freight comes from
                another source. Power only trucking allows for greater control
                of freight shipment and helps to ensure uniformity of
                transporting trailers and general trucking services across the
                entire fleet.
              </Text>
            </Box>
            <Box maxW="550px">
              <Box maxW="439px" mb="44px">
                <Image
                  src={PowerOnlyLoads}
                  data-aos="zoom-in-down"
                  data-aos-duration="1000"
                />
              </Box>

              <Box>
                <Image
                  src={PowerTrack}
                  data-aos="zoom-out-up"
                  data-aos-duration="1000"
                />
              </Box>
            </Box>
          </SimpleGrid>

          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            justifyContent="space-between"
          >
            <Box maxW={{ base: '100%', md: '455px' }} mr="40px" mb="66px">
              <Box>
                <Box display="flex" alignItems="center" mb="16px">
                  <Box
                    bg="#68D585"
                    w="30px"
                    h="30px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    fontWeight="700"
                    rounded="50%"
                    color="white"
                    mx={{ base: '10px', md: '20px' }}
                  >
                    <Text
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      w="30px"
                      h="30px"
                    >
                      ?
                    </Text>
                  </Box>
                  <Text
                    fontWeight="700"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                  >
                    How Does Power Only Trucking Work?
                  </Text>
                </Box>
                <Text
                  maxW="1000px"
                  color="#7A7A7A"
                  fontSize="14.2px"
                  lineHeight="23px"
                  ml={{ base: '50px', md: '70px' }}
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  When shippers opt for power only trucking, the driver does not
                  bring a trailer along with them for the purpose of loading the
                  cargo onto said trailer. Instead, they drive the truck to the
                  designated location and hook up to a trailer that is already
                  prepared at the location.
                  <br /> Often, the trailer will be pre-loaded as well, making
                  it easy for the driver to hook the trailer up to their truck
                  and get going. The trailer is commonly leased or owned by the
                  shipper, meaning it is not associated with the driver.
                  <br />
                  Training to become a driver for a power only trucking company
                  is much the same as any other commercial driver certification.
                  It can be started online through the Federal Motor Carrier
                  Safety Administration training programs.
                </Text>
              </Box>
            </Box>
            <Box maxW={{ base: '100%', md: '489px' }}>
              <Box mb={{ base: '30px', md: '39px' }}>
                <Box display="flex" alignItems="center" mb="16px">
                  <Box
                    bg="#68D585"
                    w="30px"
                    h="30px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    fontWeight="700"
                    rounded="50%"
                    color="white"
                    mx={{ base: '10px', md: '20px' }}
                  >
                    <Text
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      w="30px"
                      h="30px"
                    >
                      ?
                    </Text>
                  </Box>
                  <Text
                    fontWeight="700"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                  >
                    Is Drop and Hook the Same As Power Only?
                  </Text>
                </Box>
                <Text
                  maxW="1000px"
                  color="#7A7A7A"
                  fontSize="14.2px"
                  lineHeight="23px"
                  ml={{ base: '50px', md: '70px' }}
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  Yes, drop and hook is the same as power only trucking.
                  Essentially, drop and hook refers to the simple hook-up
                  process that is used when transporting trailers.
                </Text>
              </Box>
              <Box mb={{ base: '30px', md: '39px' }}>
                <Box display="flex" alignItems="center" mb="16px">
                  <Box
                    bg="#68D585"
                    w="30px"
                    h="30px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    fontWeight="700"
                    rounded="50%"
                    color="white"
                    mx={{ base: '10px', md: '20px' }}
                  >
                    <Text
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      w="30px"
                      h="30px"
                    >
                      ?
                    </Text>
                  </Box>
                  <Text
                    fontWeight="700"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                  >
                    How Do I Get Power Only Loads?
                  </Text>
                </Box>
                <Text
                  maxW="1000px"
                  color="#7A7A7A"
                  fontSize="14.2px"
                  lineHeight="23px"
                  ml={{ base: '50px', md: '70px' }}
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  These kinds of load options are often available through
                  freight brokers or online digital freight match-up services.
                </Text>
              </Box>
              <Box>
                <Box display="flex" alignItems="center" mb="16px">
                  <Box
                    bg="#68D585"
                    w="30px"
                    h="30px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    fontWeight="700"
                    rounded="50%"
                    color="white"
                    mx={{ base: '10px', md: '20px' }}
                  >
                    <Text
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      w="30px"
                      h="30px"
                    >
                      ?
                    </Text>
                  </Box>
                  <Text
                    fontWeight="700"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                  >
                    Is Power Only Trucking Profitable?
                  </Text>
                </Box>
                <Text
                  maxW="1000px"
                  color="#7A7A7A"
                  fontSize="14.2px"
                  lineHeight="23px"
                  ml={{ base: '50px', md: '70px' }}
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  As a highly profitable service, there is a great deal of money
                  to be saved and earned on both ends of the power only trucking
                  arrangement. As such, power only trucking is very profitable.
                </Text>
              </Box>
            </Box>
          </SimpleGrid>
        </Container>
      </Box>
    </section>
  )
}

export default PowerOnly
