import { Routes, Route } from 'react-router-dom'
import './App.scss'
import {
  ContactUsPage,
  DispatchCoursesPage,
  HomePage,
  JoinUsPage,
  NotFound,
} from './pages'
import { Footer, Header } from './components'

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path={'/'} element={<HomePage />} />
        <Route path={'/dispatch-courses'} element={<DispatchCoursesPage />} />
        <Route path={'/join-us'} element={<JoinUsPage />} />
        <Route path={'/contact-us'} element={<ContactUsPage />} />
        <Route path={'*'} element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  )
}

export default App
