import React, { useEffect } from 'react'
import { Box, Container, Text } from '@chakra-ui/layout'
import { Image } from '@chakra-ui/image'
import { FaQuestion } from 'react-icons/fa'
import AOS from 'aos'
import 'aos/dist/aos.css'

//local
import BoxTruk from '../../../assets/image/boxtruck.png.png'
import BoxTruk1 from '../../../assets/image/boxtruck2.png.png'

const BoxTruck = () => {
  const texts = [
    {
      theme: '26-ft Box Truck Size',
      desc1: 'Cargo Capacity- 1,800 cubic feet',
      desc2: 'Payload Capabilities- Up to 10,000 pounds',
      desc3: 'Seating Capacity- 3',
    },
    {
      theme: '16-ft Box Truck Size',
      desc1: 'Cargo Capacity- 960 cubic feet',
      desc2: 'Payload Capabilities- Up to 7,500 pounds',
      desc3: 'Seating Capacity- 3',
    },
    {
      theme: '12-ft Box Truck Size',
      desc1: 'Cargo Capacity- 380 cubic feet',
      desc2: 'Payload Capabilities-3,610 pounds',
      desc3: 'Seating Capacity- 2',
    },
    {
      theme: '22-ft Box Truck Size',
      desc1: 'Exterior Box Dimensions: 22’6″L x 8’6″W x 8’6.5″',
      desc2: 'Interior Box Dimensions: 21’L x 8’W x 8’H',
      desc3: '',
    },
    {
      theme: '26-ft Box Truck Size',
      desc1: 'Exterior Box Dimensions: 26’6″L x 8’6″W x 8’6.5″',
      desc2: 'Interior Box Dimensions: 25’L x 8’W x up to 8′ H',
      desc3: '',
    },
  ]

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <Box background="#F4F7FA" py={['20px', '35px', '60px']}>
      <Container maxW="1170px" mx="auto">
        <Box w="100%">
          <Box
            w="100%"
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Box
              w={['100%', '100%', '50%']}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Image
                src={BoxTruk}
                maxW={['95%', '95%', '95%', '95%', '95%', '460px']}
                mx={["auto", "auto", "0"]}
                data-aos="zoom-in"
                data-aos-duration="1000"
              />
              <Image
                src={BoxTruk1}
                maxW={['95%', '95%', '95%', '95%', '95%', '490px']}
                mx="auto"
                marginTop="30px"
                data-aos="flip-down"
                data-aos-duration="1000"
              />
            </Box>
            <Box
              w={['100%', '100%', '50%']}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Box
                ml={['20px', '40px', '0']}
                data-aos="zoom-in-left"
                data-aos-duration="1000"
              >
                <Text
                  maxW="318px"
                  mx="auto"
                  color="dark"
                  fontFamily="Inter"
                  fontWeight="700"
                  fontSize={['24px', '33px']}
                  marginTop="40px"
                >
                  Box Truck
                </Text>
                <Text
                  maxW="310px"
                  fontFamily="Inter"
                  mx="auto"
                  fontWeight="400"
                  fontSize="17px"
                  color="#7A7A7A"
                  textAlign="left"
                  mt="30px"
                >
                  A <span style={{ fontWeight: '700' }}>box truck—also</span>{' '}
                  known as a{' '}
                  <span style={{ fontWeight: '700' }}>
                    box van, cube van, bob truck
                  </span>{' '}
                  or <span style={{ fontWeight: '700' }}>cube truck</span>-is a
                  chassis cab truck with an enclosed cuboid-shaped cargo area
                </Text>
                <Text
                  maxW="310px"
                  fontFamily="Inter"
                  fontWeight="400"
                  fontSize="17px"
                  color="#7A7A7A"
                  textAlign="left"
                  mx="auto"
                >
                  On most box trucks, the cabin is separate to the cargo area;
                  however some box trucks have a door between the cabin and the
                  cargo area.
                </Text>
              </Box>
              <Box
                mt={['30px', '50px', '70px']}
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="300"
                data-aos-offset="0"
              >
                <Box display="flex" alignItems="center">
                  <Box
                    alignItems="center"
                    justifyContent="center"
                    background="#68D585"
                    borderRadius="50%"
                    w="30px"
                    h="30px"
                    display="flex"
                    p="10px"
                  >
                    <FaQuestion fontWeight="900" color="#ffffff" />
                  </Box>
                  <Text
                    fontFamily="Inter"
                    fontWeight="700"
                    color="#000000"
                    fontSize="20px"
                    pl={['10px', '20px']}
                  >
                    WHAT SIZE BOX TRUCKS ARE THERE?
                  </Text>
                </Box>
                <Text
                  pl={['40px', '50px']}
                  pr={['10px', '20px']}
                  mt="15px"
                  maxW="450px"
                  color="#7A7A7A"
                  fontFamily="Inter"
                  fontWeight="400"
                  fontSize="15px"
                >
                  So, when shopping for a box truck, what exactly are your
                  options? You can find a variety of box truck lengths, that
                  range from 10 – 26 feet and a number of sizes in between.
                  Popular box truck sizes that you can pick from include:
                </Text>
                <Box pl={['40px', '70px', '90px']}>
                  <Text
                    color="#404040"
                    fontFamily="Inter"
                    fontWeight="400"
                    fontSize="15px"
                  >
                    18-ft box truck
                  </Text>
                  <Text
                    color="#404040"
                    fontFamily="Inter"
                    fontWeight="400"
                    fontSize="15px"
                  >
                    22-ft box truck
                  </Text>
                  <Text
                    color="#404040"
                    fontFamily="Inter"
                    fontWeight="400"
                    fontSize="15px"
                  >
                    24-ft box truck
                  </Text>
                  <Text
                    color="#404040"
                    fontFamily="Inter"
                    fontWeight="400"
                    fontSize="15px"
                  >
                    26-ft box truck
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box py="20px">
            <Text
              color="dark"
              fontFamily="Roboto"
              fontSize="19px"
              fontWeight="700"
              mt="30px"
              px="10px"
            >
              BOX TRUCK SIZES: CARGO & PAYLOAD CAPACITIES
            </Text>
            <Text
              color="#000000"
              fontFamily="Roboto"
              fontSize="19px"
              fontWeight="400"
              mt="20px"
              px="10px"
            >
              The exact capabilities of each size box truck will depend a lot on
              the make and model. Still, there are some general guidelines of
              what you can expect from different box truck sizes. Below you’ll
              get some idea of what to expect as far as capabilities for popular
              box truck sizes:
            </Text>
            <Box
              justifyContent="center"
              w="100%"
              display="flex"
              flexWrap="wrap"
            >
              {texts.map((elem, idx) => (
                <Box
                  key={idx}
                  w={['100%', '100%', '50%']}
                  marginTop="30px"
                  data-aos={
                    idx === 0
                      ? 'fade-right'
                      : idx === 2
                      ? 'fade-left'
                      : idx === 3
                      ? 'zoom-in-left'
                      : 'zoom-in'
                  }
                  data-aos-duration="1000"
                >
                  <Text
                    color="dark"
                    fontFamily="Roboto"
                    fontWeight="700"
                    fontSize="19px"
                  >
                    {elem.theme}
                  </Text>
                  <Box paddingLeft="30px" marginTop="10px">
                    <Text>{elem.desc1}</Text>
                    <Text>{elem.desc2}</Text>
                    <Text>{elem.desc3}</Text>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default BoxTruck
