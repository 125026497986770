import React from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  Text,
} from '@chakra-ui/react'

function BackdropExample({ isOpen, onClose, overlay, status, text }) {
  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        {overlay}
        <ModalContent maxW={['90%', '70%', '460px']}>
          <ModalCloseButton />
          <ModalBody>
            <Text
              pt="20px"
              pb="10px"
              mr="20px"
              textAlign="center"
              color={status ? 'green' : 'red'}
              fontSize="19px"
              fontWeight="500"
            >
              {text}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button mx="auto" onClick={onClose}>
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default BackdropExample
