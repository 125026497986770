import axios from "axios";
import {
  Box,
  Button,
  Container,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";

import { countries } from "../../json/Counrties";
// import { CloseIcon } from "@chakra-ui/icons";
import "./style.css";

const CarrierSetUp = () => {
  const ref = useRef();
  const [image, setImage] = useState("");
  const [validate, setValidate] = useState({
    username: false,
    email: false,
    phone: false,
  });
  const [postData, setPostData] = useState({
    username: "",
    dba: "",
    address: "",
    apartment: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    email: "",
    phone: "",
    usdot: "",
    mc: "",
    ssn: "",
    number_of_drivers: "",
    number_of_truck: "",
    type_of_equipment: image,
    desc: "",
  });

  const valueInput = [
    {
      id: "1",
      text: "Yes",
      value: "yes",
    },
    {
      id: "2",
      text: "No",
      value: "no",
    },
  ];

  const onChange = async (e) => {
    e.preventDefault();

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    try {
      const reader = await new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(files[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async () => {
    if (postData.username.length <= 0) {
      setValidate({ ...validate, username: true });
    } else if (postData.phone.length <= 0) {
      setValidate({ ...validate, phone: true });
    } else if (postData.email.length <= 0) {
      setValidate({ ...validate, email: true });
    } else {
      await axios
        .post("https://usdiscorp.pythonanywhere.com/usdispatch/career/", postData)
        .then(() => {
          alert("Success");
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const optionList = [
    {
      id: "1",
      text: "Dry Van",
    },
    {
      id: "2",
      text: "Reefer",
    },
    {
      id: "3",
      text: "Flatbed",
    },
    {
      id: "4",
      text: "Conestoga",
    },
  ];

  return (
    <section>
      <Box bg="#F4FCFF" py="81px">
        <Container maxW="1200px">
          <Box display="flex" flexWrap="wrap" justifyContent="space-between">
            <Box mb="33px" maxW="399.73px">
              <Text
                fontSize="56px"
                maxW="423px"
                fontWeight="700"
                mb="37px"
                color="dark"
              >
                Carrier Set-Up
              </Text>
              <Text fontSize="18px" color="dark">
                Once you complete the carrier setup below, we will email you a
                Dispatch Service Agreement along with a General Power Of
                Attorney (Agent) and you’ll all be set. Don’t forget to attach
                your MC Authority, W9 & Insurance below!
              </Text>
            </Box>
            <Box
              border="1px solid #C1C1C1"
              bg="white"
              w={{ base: "100%", md: "50%" }}
              rounded="10px"
              px="30px"
              py="10px"
              display="flex"
              flexDir="column"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                flexDir={{ base: "column", lg: "row" }}
              >
                <Input
                  maxW="288px"
                  variant="flushed"
                  placeholder="Name *"
                  name="username"
                  mb="30px"
                  mr="20px"
                  onChange={(e) =>
                    setPostData({ ...postData, username: e.target.value })
                  }
                />
                {validate.username && <Text>This is username</Text>}
                <Input
                  variant="flushed"
                  placeholder="DBA (if any)"
                  mb="30px"
                  onChange={(e) =>
                    setPostData({ ...postData, dba: e.target.value })
                  }
                />
              </Box>
              <Input
                variant="flushed"
                placeholder="Street Address"
                mb="30px"
                onChange={(e) =>
                  setPostData({ ...postData, address: e.target.value })
                }
              />
              <Input
                variant="flushed"
                placeholder="Apartment, suite, etc"
                mb="30px"
                onChange={(e) =>
                  setPostData({ ...postData, apartment: e.target.value })
                }
              />
              <Box
                display="flex"
                justifyContent="space-between"
                flexDir={{ base: "column", lg: "row" }}
              >
                <Input
                  maxW="288px"
                  variant="flushed"
                  placeholder="City"
                  mb="30px"
                  mr="20px"
                  onChange={(e) =>
                    setPostData({ ...postData, city: e.target.value })
                  }
                />
                <Input
                  variant="flushed"
                  placeholder="State/Province"
                  mb="30px"
                  onChange={(e) =>
                    setPostData({ ...postData, state: e.target.value })
                  }
                />
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                flexDir={{ base: "column", lg: "row" }}
              >
                <Input
                  maxW="288px"
                  variant="flushed"
                  placeholder="ZIP / Postal Code"
                  mb="30px"
                  mr="20px"
                  onChange={(e) =>
                    setPostData({ ...postData, zip: e.target.value })
                  }
                />
                <Select
                  variant="flushed"
                  mb="30px"
                  onChange={(e) =>
                    setPostData({ ...postData, country: e.target.value })
                  }
                >
                  <option defaultValue="">Countries</option>
                  {countries.map((items, index) => (
                    <option key={index}>{items.name}</option>
                  ))}
                </Select>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                flexDir={{ base: "column", lg: "row" }}
              >
                <Input
                  maxW="288px"
                  variant="flushed"
                  placeholder="Email Address *"
                  name="email"
                  mb="30px"
                  mr="20px"
                  onChange={(e) =>
                    setPostData({ ...postData, email: e.target.value })
                  }
                />
                {validate.email && <Text>This is email</Text>}
                <Input
                  variant="flushed"
                  placeholder="Phone *"
                  name="phone"
                  mb="30px"
                  onChange={(e) =>
                    setPostData({ ...postData, phone: e.target.value })
                  }
                />
                {validate.phone && <Text>This is phone</Text>}
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                flexDir={{ base: "column", lg: "row" }}
              >
                <Input
                  maxW="288px"
                  variant="flushed"
                  placeholder="USDOT#"
                  mb="30px"
                  mr="20px"
                  onChange={(e) =>
                    setPostData({ ...postData, usdot: e.target.value })
                  }
                />
                <Input
                  variant="flushed"
                  placeholder="MC#"
                  mb="30px"
                  onChange={(e) =>
                    setPostData({ ...postData, mc: e.target.value })
                  }
                />
              </Box>
              <Input
                variant="flushed"
                placeholder="FEIN/SSN"
                mb="30px"
                onChange={(e) =>
                  setPostData({ ...postData, ssn: e.target.value })
                }
              />
              <Box
                display="flex"
                justifyContent="space-between"
                flexDir={{ base: "column", lg: "row" }}
              >
                <Input
                  maxW="288px"
                  variant="flushed"
                  placeholder="Number Of Drivers?"
                  mb="30px"
                  mr="20px"
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      number_of_drivers: e.target.value,
                    })
                  }
                />
                <Input
                  variant="flushed"
                  placeholder="Number Of Trucks?"
                  mb="30px"
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      number_of_truck: e.target.value,
                    })
                  }
                />
              </Box>
              <Box>
                <Text fontSize="11px" fontWeight="700" color="#777771" mb="7px">
                  Type Of Equipment?
                </Text>
                <Input
                  variant="flushed"
                  placeholder="FEIN/SSN"
                  mb="30px"
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      type_of_equipment: e.target.value,
                    })
                  }
                />
                <Select variant="flushed" mb="30px">
                  {optionList.map((item, index) => (
                    <option key={index}>{item.text}</option>
                  ))}
                </Select>
              </Box>

              <Box mb="30px">
                <Text
                  fontSize="11px"
                  fontWeight="700"
                  color="#777771"
                  mb="14px"
                >
                  Do you factor your invoices?
                </Text>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  maxW="135px"
                  mb="30px"
                >
                  <RadioGroup>
                    <Stack spacing={4} direction="row">
                      {valueInput.map((items, index) => (
                        <Radio
                          value={items.value}
                          key={index}
                          name="factor_invoices"
                          onChange={(e) =>
                            setPostData({
                              ...postData,
                              factor_invoices: e.target.value,
                            })
                          }
                        >
                          <Text ml="5px" fontWeight="400" fontSize="14px">
                            {items.text}
                          </Text>
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                </Box>
                <Textarea
                  placeholder="What State Do You Prefer To Drive?"
                  rounded="2px"
                  h="150px"
                  onChange={(e) =>
                    setPostData({ ...postData, desc: e.target.value })
                  }
                ></Textarea>
              </Box>
              <Text fontSize="11px" fontWeight="700" color="#777771" mb="14px">
                Upload MC Authority Letter, Certificate Of Liability Insurance,
                W9
              </Text>
              <Box display="flex" alignItems="center" mb="30px">
                <Button
                  onClick={() => ref.current?.click()}
                  bg="#1ABCA1"
                  colorScheme="#17A8E3"
                  w="115px"
                  fontSize="13px"
                  fontWeight="600"
                  rounded="2px"
                >
                  Choose file
                </Button>
                <Text fontSize="11px" fontWeight="700" color="#777771">
                  <input
                    type="file"
                    ref={ref}
                    onChange={onChange}
                    style={{
                      width: "100px",
                      marginLeft: "20px",
                      marginRight: "20px",
                    }}
                  />
                </Text>
                {/* <CloseIcon w={3} h={3} color="red.500" /> */}
              </Box>
              <Button
                bg="#17A8E3"
                colorScheme="#17A8E3"
                w="115px"
                fontSize="13px"
                fontWeight="500"
                mb="30px"
                rounded="2px"
                onClick={onSubmit}
              >
                Request Quote
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </section>
  );
};

export default CarrierSetUp;
