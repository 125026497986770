import React, { useEffect } from 'react'
import { Box, Container, Text } from '@chakra-ui/react'
import { FaCheck } from 'react-icons/fa'
import AOS from 'aos'
import 'aos/dist/aos.css'

const EducationalTraining = () => {
  const text = [
    {
      learnText: 'How to fill out an agreement with a factoring company.',
    },
    {
      learnText: 'How to find a driver',
    },
    {
      learnText: 'How to fill set up package',
    },
    {
      learnText: 'How to fill set up package',
    },
    {
      learnText: 'How to calculate the gross, miles costs etc.',
    },
    {
      learnText: 'Planning tools',
    },
    {
      learnText: 'How to write safety plan',
    },
    {
      learnText: 'How to get TWIC cards',
    },
    {
      learnText: 'How to apply for CARB certificate',
    },
    {
      learnText:
        'Types of documentations (BOL, W-9, agreements, Rat Con, Set up package etc.)',
    },
    {
      learnText: 'The nuances at the conclusion of contracts',
    },
    {
      learnText:
        'Different kind of load boards Amazon Relay/ DAT/ Truckstop/ J.B.Hunt/',
    },
    {
      learnText: 'How to apply FMCSA to get( MC/ DOT) NMFTA',
    },
    {
      learnText: 'How to do the reports',
    },
    {
      learnText: 'How to analyze market',
    },
    {
      learnText: 'Route plan etc.',
    },
  ]

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <Box>
      <Container maxW="1170px" mx="auto">
        <Text
          color="#2F4B6E"
          fontFamily="Nunito"
          fontWeight="700"
          lineHeight={['46px', '57px']}
          fontSize={['34px', '38px', '42px']}
          textAlign="center"
          maxW="920px"
          mx="auto"
          mt="20px"
        >
          What will you learn in our Educational Training Center?
        </Text>
        <Box
          w="100%"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          pt={['20px', '50px']}
          px={['', '20px', '20px']}
          pb={['30px', '50px', '70px']}
        >
          {text.map((el, idx) => (
            <Box key={idx} display="flex" mt="30px" w={['100%', '95%', '45%']}>
              <Box
                background="#473BF0"
                w="35px"
                h="35px"
                borderRadius="50%"
                p="10px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <FaCheck color="#ffffff" />
              </Box>
              <Text
                px={['10px', '20px']}
                color="dark"
                fontFamily="Inter"
                fontWeight="700"
                fontSize="21px"
                data-aos-duration="1000"
                data-aos="fade-left"
              >
                {el.learnText}
              </Text>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  )
}

export default EducationalTraining
