export default function SvgFacebook() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.1 3.84766C5.85 3.84766 4.05703 5.67578 4.05703 7.89062C4.05703 10.1406 5.85 11.9336 8.1 11.9336C10.3148 11.9336 12.143 10.1406 12.143 7.89062C12.143 5.67578 10.3148 3.84766 8.1 3.84766ZM8.1 10.5273C6.65859 10.5273 5.46328 9.36719 5.46328 7.89062C5.46328 6.44922 6.62344 5.28906 8.1 5.28906C9.54141 5.28906 10.7016 6.44922 10.7016 7.89062C10.7016 9.36719 9.54141 10.5273 8.1 10.5273ZM13.2328 3.70703C13.2328 3.17969 12.8109 2.75781 12.2836 2.75781C11.7562 2.75781 11.3344 3.17969 11.3344 3.70703C11.3344 4.23438 11.7562 4.65625 12.2836 4.65625C12.8109 4.65625 13.2328 4.23438 13.2328 3.70703ZM15.9047 4.65625C15.8344 3.39062 15.5531 2.26562 14.6391 1.35156C13.725 0.4375 12.6 0.15625 11.3344 0.0859375C10.0336 0.015625 6.13125 0.015625 4.83047 0.0859375C3.56484 0.15625 2.475 0.4375 1.52578 1.35156C0.611719 2.26562 0.330469 3.39062 0.260156 4.65625C0.189844 5.95703 0.189844 9.85938 0.260156 11.1602C0.330469 12.4258 0.611719 13.5156 1.52578 14.4648C2.475 15.3789 3.56484 15.6602 4.83047 15.7305C6.13125 15.8008 10.0336 15.8008 11.3344 15.7305C12.6 15.6602 13.725 15.3789 14.6391 14.4648C15.5531 13.5156 15.8344 12.4258 15.9047 11.1602C15.975 9.85938 15.975 5.95703 15.9047 4.65625ZM14.2172 12.5312C13.9711 13.2344 13.4086 13.7617 12.7406 14.043C11.6859 14.4648 9.225 14.3594 8.1 14.3594C6.93984 14.3594 4.47891 14.4648 3.45937 14.043C2.75625 13.7617 2.22891 13.2344 1.94766 12.5312C1.52578 11.5117 1.63125 9.05078 1.63125 7.89062C1.63125 6.76562 1.52578 4.30469 1.94766 3.25C2.22891 2.58203 2.75625 2.05469 3.45937 1.77344C4.47891 1.35156 6.93984 1.45703 8.1 1.45703C9.225 1.45703 11.6859 1.35156 12.7406 1.77344C13.4086 2.01953 13.9359 2.58203 14.2172 3.25C14.6391 4.30469 14.5336 6.76562 14.5336 7.89062C14.5336 9.05078 14.6391 11.5117 14.2172 12.5312Z"
        fill="#7D818D"
      />
    </svg>
  );
}
