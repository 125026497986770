import { Box, Container, Image, Text } from "@chakra-ui/react";
import Usdiscore from "../../assets/image/usdiscorp.png";
import SvgFacebook from "../../assets/svg/SvgFacebook";
import SvgInstagram from "../../assets/svg/SvgInstagram";
import SvgLinkedin from "../../assets/svg/SvgLinkedin";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const listMenu = [
    {
      item: "Dispatch Courses",
    },
    {
      item: "English courses",
    },
    {
      item: "Dispatch Services",
    },
    {
      item: "Owner operators",
    },
    {
      item: "Company drivers",
    },
    {
      item: "Freight brokers",
    },
  ];

  const navigate = useNavigate();

  return (
    <Box bg="dark">
      <Container maxW="1200px">
        <Box
          py="130px"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <Box
            w={{ base: "173px", sm: "200px" }}
            mb={{ bas: "79px", md: "10px" }}
          >
            <Text mb={{ bas: "79px", md: "10px" }} color="white" pb="15px" fontSize="md">usdiscorp.com</Text>
            <Box display="flex" justifyContent="space-between" maxW="100px">
              <Box
                cursor="pointer"
                onClick={() => {
                  window.open(
                    "https://www.facebook.com/US-Dispatch-Corporation-105643918554825"
                  );
                }}
              >
                <SvgInstagram />
              </Box>
              <Box
                  cursor="pointer"
                  onClick={() => {
                    window.open(
                        "https://www.instagram.com/"
                    );
                  }}
              >
                <SvgFacebook />
              </Box>
              <Box
                  cursor="pointer"
                  onClick={() => {
                    window.open(
                        "https://www.linkedin.com/"
                    );
                  }}
              >
                <SvgLinkedin />
              </Box>
            </Box>
          </Box>
          <Box
            w={{ base: "173px", sm: "200px" }}
            mb={{ bas: "79px", md: "10px" }}
          >
            <Text color="rgba(255, 255, 255, 0.57)" pb="23px">
              Pages
            </Text>
            {listMenu.slice(0, 3).map((item, index) => (
              <Text
                color="white"
                lineHeight="20px"
                pb="20px"
                _hover={{ color: `${index === 0 && "#61CE70"}` }}
                cursor={index === 0 && "pointer"}
                key={index}
                onClick={() => {
                  index === 0 && navigate("/dispatch-courses");
                }}
              >
                {item.item}
              </Text>
            ))}
          </Box>
          <Box
            w={{ base: "173px", sm: "200px" }}
            mb={{ bas: "79px", md: "10px" }}
          >
            <Text color="rgba(255, 255, 255, 0.57)" lineHeight="20px" pb="23px">
              Join Us!
            </Text>
            {listMenu.slice(3).map((item, index) => (
              <Text color="white" key={index} lineHeight="20px" pb="20px">
                {item.item}
              </Text>
            ))}
          </Box>
          <Box w={{ base: "173px", sm: "200px" }}>
            <Text color="rgba(255, 255, 255, 0.57)" lineHeight="20px" pb="23px">
              Contacts
            </Text>

            <Text
              color="white"
              cursor="pointer"
              _hover={{ color: "#61CE70" }}
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Contacts
            </Text>
          </Box>
        </Box>
      </Container>
      <Box
        mx="auto"
        display="flex"
        justifyContent="center"
        alignItems="center"
        pb="30px"
      >
        <Text textColor="white" mr="10px">
          Developed by
        </Text>
        <a href="https://motionweb.dev" target="_blank">
          <Image
            maxW="50px"
            src="https://limitless-connection.com/_next/static/images/logo-3-54f7c1087d10264b209894dc676de6e7.png"
          />
        </a>
      </Box>
    </Box>
  );
}
