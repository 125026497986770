import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Input,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import BackdropExample from "../../modal/modalApi";
import LoadForm from "../../loading/LoadForm";

const OwnerOperators = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );
  const [load, setLoad] = useState(false);
  const [overlay, setOverlay] = useState(<OverlayOne />);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [status, setStatus] = useState(false);
  const [text, setText] = useState("");

  const OpenModal = () => {
    setOverlay(<OverlayOne />);
    onOpen();
    setLoad(false);
  };

  function onSubmit(values) {
    setLoad(true);
    return new Promise((resolve) => {
      axios
        .post("https://usdiscorp.pythonanywhere.com/usdispatch/owner/", {
          ...values,
        })
        .then(({ data }) => {
          setStatus(true);
          setText("Data sent successfully!");
          OpenModal();
          //   console.log(data)
        })
        .catch((err) => {
          setStatus(false);
          setText(`An error occurred while sending data! ${err.message}`)
          OpenModal();
          //   console.log(err)
        });
      resolve();
    });
  }
  return (
    <section>
      <Box bg="#F4FCFF" py="81px" mt="90px">
        <Container maxW="1200px">
          <BackdropExample
            isOpen={isOpen}
            onClose={onClose}
            overlay={overlay}
            status={status}
            text={text}
          />
          <Box display="flex" flexWrap="wrap" justifyContent="space-between">
            <Box mb="33px">
              <Text
                fontSize={{ base: "39px", md: "57px" }}
                maxW={{ base: "100%", md: "356px" }}
                fontWeight="700"
                mb="37px"
                color="dark"
              >
                OWNER OPERATORS
              </Text>
              <Text fontSize="18px" color="dark">
                Can’t AFFORD office or hire a dispatcher?
              </Text>
            </Box>
            <Box w={["100%", "100%", "50%"]}>
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                <Box
                  border="1px solid #C1C1C1"
                  bg="white"
                  w={{ base: "100%", md: "100%" }}
                  rounded="10px"
                  px="30px"
                  py="10px"
                  display="flex"
                  flexDir="column"
                  justifyContent="space-between"
                  position="relative"
                >
                  {load && <LoadForm />}
                  <Box mb="30px">
                    <Input
                      {...register("username", {
                        required: "Enter your name",
                      })}
                      borderBottom={errors.username && "1px solid #ff0000"}
                      variant="flushed"
                      type="text"
                      placeholder="Name *"
                    />
                    <Text color="#ff0000" fontSize="12px" fontWeight="600">
                      {errors.username && errors.username?.message}
                    </Text>
                  </Box>
                  <Box mb="30px">
                    <Input
                      {...register("phone", {
                        required: "Enter your phone number",
                      })}
                      borderBottom={errors.phone && "1px solid #ff0000"}
                      variant="flushed"
                      type="tel"
                      placeholder="Phone Number *"
                    />
                    <Text color="#ff0000" fontSize="12px" fontWeight="500">
                      {errors.phone && errors.phone?.message}
                    </Text>
                  </Box>
                  <Box mb="30px">
                    <Input
                      {...register("email", {
                        required: "Enter your email address",
                        pattern: {
                          value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                          message: "Enter a valid email",
                        },
                      })}
                      variant="flushed"
                      borderBottom={errors.email && "1px solid #ff0000"}
                      type="email"
                      placeholder="Email Address *"
                    />
                    <Text color="#ff0000" fontSize="12px" fontWeight="600">
                      {errors.email && errors.email?.message}
                    </Text>
                  </Box>
                  <Box mb="30px">
                    <Text
                      fontSize="11px"
                      fontWeight="700"
                      color="#777771"
                      mb="14px"
                    >
                      Trailer type
                    </Text>
                    <Stack>
                      <Checkbox {...register("dry_van")}>
                        <Text ml="5px" fontWeight="400" fontSize="14px">
                          Dry Van
                        </Text>
                      </Checkbox>
                      <Checkbox {...register("reefer")}>
                        <Text ml="5px" fontWeight="400" fontSize="14px">
                          Reefer
                        </Text>
                      </Checkbox>
                      <Checkbox {...register("flatbed")}>
                        <Text ml="5px" fontWeight="400" fontSize="14px">
                          Flatbed
                        </Text>
                      </Checkbox>
                      <Checkbox {...register("box_truck")}>
                        <Text ml="5px" fontWeight="400" fontSize="14px">
                          Box Truck
                        </Text>
                      </Checkbox>
                    </Stack>
                  </Box>
                  <Box mb="30px">
                    <Input
                      {...register("number_of_truck", {
                        required: "Enter number of truck",
                        min: {
                          value: 1,
                          message:
                            "Please enter a value greater than or equal to 1.",
                        },
                      })}
                      variant="flushed"
                      placeholder="Number of trucks *"
                      borderBottom={errors.email && "1px solid #ff0000"}
                      type="number"
                    />
                    <Text color="#ff0000" fontSize="12px" fontWeight="600">
                      {errors.number_of_truck &&
                        errors.number_of_truck?.message}
                    </Text>
                  </Box>
                  <Button
                    bg="#17A8E3"
                    colorScheme="#17A8E3"
                    type="submit"
                    w="115px"
                    fontSize="13px"
                    fontWeight="500"
                    mb="30px"
                  >
                    Request Quote
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Container>
      </Box>
    </section>
  );
};

export default OwnerOperators;
