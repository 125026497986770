import React, { useEffect } from 'react'
import { FaTelegramPlane } from 'react-icons/fa'
import { Box, Button, Container, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'

const RegisterCourse = () => {
  const navigate = useNavigate()

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <Box>
      <Container maxW="1170px" mx="auto">
        <Box w="100%" py="30px">
          <Text
            color="#2F4B6E"
            fontFamily="Nunito"
            fontWeight="700"
            lineHeight={['46px', '57px']}
            fontSize={['34px', '38px', '42px']}
            textAlign="center"
            maxW="472px"
            mx="auto"
            data-aos-duration="1000"
            data-aos="zoom-in"
          >
            Do you want to learn all aspects of a dispatch business and trucking
            industry?
          </Text>
          <Text
            fontFamily="Nunito"
            fontWeight="400"
            fontSize={['19px', '20px', '21px']}
            color="#2F4B6E"
            textAlign="center"
            my="30px"
            data-aos-duration="1000"
            data-aos="zoom-in-left"
          >
            Register in our online and offline course!
          </Text>
          <Box w="100%" display="flex" justifyContent="center">
            <Button
              borderRadius="31px"
              display="flex"
              fontWeight="700"
              fontSize="16px"
              alignItems="center"
              color="#304C6D"
              background="#F8E587"
              textTransform="uppercase"
              data-aos-duration="1000"
              data-aos="zoom-in"
              py="25px"
              px="30px"
              colorScheme="#F8E587"
              onClick={() => {
                navigate('/contact-us')
                window.scroll(0, 0)
              }}
            >
              <FaTelegramPlane style={{ marginRight: '10px' }} />
              Register
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default RegisterCourse
